@import "variables";

.feedback-form {
  .title {
    font-size: 16px;
  }
  width: 500px;

  input[type="text"] {
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 200px;
  }

  .ui-rating a {
    outline: none !important;
  }
}
