@import "variables";

.generic-article-filter {
  p-autoComplete {
    span.ui-autocomplete {
      display: block;
      ul {
        width: 100%;
      }
    }
  }
  p-dropdown {
    .ui-dropdown {
      flex-grow: 1;
    }
  }
  h6 {
    margin-bottom: 0;
  }
}

.maxGAFavsOverlay {
  background-color: $primary-color !important;

  .ui-overlaypanel-content {
    color: $white !important;
  }
}
