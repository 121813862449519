/* You can add global styles to this file, and also import other style files */
@import "./scss/app";
@import "./scss/variables";

body {
  font-family: $ta-font;
  .ui-widget{
    font-family: $ta-font;
  }
}

