@import "variables";

.popup-get-full-version {

  form {
    margin-top: 20px;
  }

  .ui-dialog-content {
    overflow-y: hidden;
  }
  .ui-dialog-titlebar {
    background-color: #4c4b63 !important;
    color: $ta-primary-text-color !important;
    font-size: 16px;
    line-height: 20px;
    height: 45px !important;
    padding-top: 12px !important;
  }

  .feature-list {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .btn-submit {
    padding: 0%;
    button {
    background-color: #ff562b !important;
    color: $ta-primary-text-color !important;
    border-radius: 3px;
    border: none;
    padding: 6px 10px;
    font-weight: bold;
    font-size: 12px;
  }
}

  .error-div {
    height: 25px;
  }

  .error-field {
    border-right: 4px solid red;
  }

  .confirm-content {
    padding-top: 20px;
  }
}
