.datasource-selector-dropdown-panel {

  .ui-unselectable-text {
    display: none !important;
  }

  .ui-tree {
    padding: 5px;
  }

  .ui-treenode {
    padding: 0px !important;
  }

  * {
    overflow: hidden;
  }
}
