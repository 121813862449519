@import "variables";

.applyCancel {

  .btnApply {
    width: 110px !important;
    margin-left: 16px;
  }

  .btnCancel {
    background-color: white !important;
    color: #007ad9 !important;
    margin-left: 10px;
  }
}
