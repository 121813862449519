body {
  // Autocomplete
  .ui-autocomplete.ui-autocomplete-multiple {
    .ui-autocomplete-multiple-container {
      .ui-autocomplete-token {
        background: #ff562b;
        color: map-get($colors, ta-dark-blue);
        margin-bottom: 2px;
      }
    }
  }
  .ui-autocomplete-panel {
    .ui-autocomplete-items {
      .ui-autocomplete-list-item {
        &.ui-state-highlight {
          background: #ECEEEF;
          color: map-get($colors, ta-dark-blue);
          margin-bottom: 2px;
        }
      }
    }
  }

  // Dropdown
  .ui-dropdown-panel {
    .ui-dropdown-items {
      .ui-dropdown-item {
        &.ui-state-highlight {
          background: $ta-dropdown-bg;
          color: map-get($colors, ta-dark-blue);
        }
        &:not(.ui-state-highlight):not(.ui-state-disabled):hover {
          background: $ta-dropdown-bg;
          color: map-get($colors, ta-dark-blue);
        }
      }
    }
    .ui-dropdown-item-group {
      &.ui-state-highlight {
        background: map-get($colors, ta-green);
        color: map-get($colors, ta-dark-blue);
      }
      &:not(.ui-state-highlight):not(.ui-state-disabled):hover {
        background: map-get($colors, ta-green);
        color: map-get($colors, ta-dark-blue);
      }
    }
  }

  // Multiselect dropdown
  .ui-multiselect-header {
    .ui-inputtext {
      &:focus {
        border-color: $ta-active-color !important;
      }
    }
  }
  .ui-multiselect-panel {
    .ui-multiselect-items {
      .ui-multiselect-item {
        &.ui-state-highlight {
          background: $ta-dropdown-bg;
          color: map-get($colors, ta-dark-blue);
        }
        &:not(.ui-state-highlight):not(.ui-state-disabled):hover {
          background: $ta-dropdown-bg;
          color: map-get($colors, ta-dark-blue);
        }
      }
    }
  }
  .ui-chkbox {
    .ui-chkbox-box {
      &.ui-state-active {
        color: map-get($colors, ta-white);
        background-color: map-get($colors, ta-dark-blue);
        border-color: map-get($colors, ta-dark-blue);
      }
    }
  }

  // Calendar
  .ui-datepicker {
    table {
      td {
        padding: 0.2em;
        > a {
          padding: 0.2em;
          &.ui-state-active {
            color: map-get($colors, ta-white);
            background-color: map-get($colors, ta-dark-blue);
          }
        }
        > span {
          padding: 0.2em;
          &.ui-state-active {
            color: map-get($colors, 'ta-white');
            background-color: map-get($colors, 'ta-blue');
          }
        }
      }
    }
  }
  // ui-treenode-selectable

  li.ui-treenode {
    .ui-treenode-content {
      &.ui-treenode-content-selected, &:hover {
        background: $ta-dropdown-bg;
      }
      .ui-treenode-label {
        &.ui-state-highlight {
          background: transparent !important;
          color: #333333 !important;
        };
        &:hover {
          background: transparent !important;
        }
      }
    }
  }



  // UI Dialog
  .ui-dialog {
    .ui-dialog-titlebar {

    }
  }
}
