@charset "UTF-8";

@import "animations",
"ta-icon",
"popup_get_full_version",
"footer",
"variables",
"main",
"feedback",
"visitors",
"piwik-consent",
"filter-multiselect",
"ga-filter",
"filter-apply",
"card",
"lealef",
"vehicles_page",
"ta-theme",
"datasource-selector",
"datasource-item";

pre.language-html::before, pre.language-html::after {
    box-shadow: none;
}

$tour-zindex : 2000;
@import '../../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';


// a {
//     color: $ta-link-color !important;
//     &:active, &:hover {
//         color: $ta-link-color-active !important;
//     }
// }

button {
    &.btn-primary {
        background-color: $ta-primary-button-bg-color !important;
        &:hover {
            background: $ta-primary-button-bg-color-hover !important;
        }
    }

}

button.default-button, a.default-button {
  background-color: $ta-primary-button-bg-color !important;
  border: 1px solid $ta-primary-button-bg-color !important;
  &:hover {
      background: $ta-primary-button-bg-color-hover !important;

  }
    &:focus, &:active {
      box-shadow: 0 0 5px #5c89b6 !important;
      border: 1px solid #0043a8 !important;
      outline: none;
  }
}

.default-button {
    // background-color: $ta-primary-button-bg-color !important;
    // border: 1px solid $ta-primary-button-bg-color !important;

    button {
        background-color: $ta-primary-button-bg-color !important;
        border: 1px solid $ta-primary-button-bg-color !important;
        &:hover {
            background: $ta-primary-button-bg-color-hover !important;

        }
    }


    &:focus, &:active {
        box-shadow: 0 0 5px #5c89b6 !important;
        border: 1px solid #0043a8 !important;
        outline: none;
    }
}

.borderless-icon-button {
    color: $ta-active-color;
    border-radius: 3px;
    &:hover {
        background-color: #ECEEEF !important;

    }

    &:focus, &:active {
        border: 1px solid $ta-active-color;
    }
}


[styleclass=ta-tabmenu-container] {
    .ui-state-active {
      background-color: #0052cf !important;
      border: 1px solid #0052cf;
    }
}

[styleclass = ta-dataview] {
    .ui-state-active {
      background-color: #0052cf !important;
      border: 1px solid #0052cf;
    }
}
