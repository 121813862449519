.ta-multiselect {
  .ui-multiselect {
    flex-grow: 1;
    margin-right: 10px;

    .multiselect-clear-btn {
        background-image: url("~assets/images/icons/btn_reset_multiple_filter.png");
        background-size: 30px auto;
        background-position: -3px -3px;
        width: 25px;
        height: 25px;
        position: absolute;
        right: -25px;
        top: 7px;
        display: block;
        cursor: pointer;
    }
  }
}

.analytics-filter {

  .ui-widget-header.ui-corner-all.ui-multiselect-header {
    padding-right: 0 !important;
  }

  .ui-multiselect-filter-container {
    width: 80% !important;
  }

  .ui-multiselect-close {
    margin-right: 1.5em;
  }

  .ui-inputtext{
    box-shadow: none !important;
  }
}
