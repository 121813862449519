@import "variables";

/**
 * Ellipsis Blink
 */

@-webkit-keyframes ellipsis-blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@keyframes ellipsis-blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

.ellipsis-blink {
    & span {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        -webkit-animation-name: ellipsis-blink;
        animation-name: ellipsis-blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }

    & span:nth-child(2) {
        animation-delay: .2s;
    }

    & span:nth-child(3) {
        animation-delay: .4s;
    }
}

/**
 * Tec Ellipsis Blink
 */

@-webkit-keyframes tec-ellipsis-blink {
    0% {
        color: $secondary-color;
        opacity: 1;
    }
    20% {
        color: $primary-color;
        opacity: 1;
    }
    100% {
        color: $secondary-color;
        opacity: 1;
    }
}

@keyframes tec-ellipsis-blink {
    0% {
        color: $secondary-color;
        opacity: 1;
    }
    20% {
        color: $primary-color;
        opacity: 1;
    }
    100% {
        color: $secondary-color;
        opacity: 1;
    }
}

.ellipsis-blink.tec {
    & span {
        -webkit-animation-name: tec-ellipsis-blink;
        animation-name: tec-ellipsis-blink;
    }
}

/**
 * Spinner
 */

@-webkit-keyframes busy-spinner {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.25;
    }
}

@keyframes busy-spinner {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.25;
    }
}

.busy-spinner,
.busy-spinner > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 925;

    .busy-spinner-default-wrapper {
        text-align: center;

        .busy-spinner-default-sign {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            padding: 12px 14px;
            border-radius: 4px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
            background: #fff;
            color: #333;

            .busy-spinner-default-text {
                display: inline-block;
                margin-left: 6px;
                max-width: 400px;
                font-size: 14px;
                text-align: left;
            }

            .busy-spinner-default-spinner {
                position: relative;
                display: inline-block;
                width: 25px;
                height: 25px;
                vertical-align: middle;

                div {
                    position: absolute;
                    left: 44.5%;
                    top: 37%;
                    width: 10%;
                    height: 26%;
                    background: #333;
                    border-radius: 50px;
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
                    opacity: 0;
                    -webkit-animation: busy-spinner 1s linear infinite;
                            animation: busy-spinner 1s linear infinite;
                }

                .bar1 {
                    -webkit-transform: rotate(0deg) translate(0, -142%);
                            transform: rotate(0deg) translate(0, -142%);
                    -webkit-animation-delay: -1s;
                            animation-delay: -1s;
                }
                .bar2 {
                    -webkit-transform: rotate(30deg) translate(0, -142%);
                            transform: rotate(30deg) translate(0, -142%);
                    -webkit-animation-delay: -0.91666667s;
                            animation-delay: -0.91666667s;
                }
                .bar3 {
                    -webkit-transform: rotate(60deg) translate(0, -142%);
                            transform: rotate(60deg) translate(0, -142%);
                    -webkit-animation-delay: -0.83333333s;
                            animation-delay: -0.83333333s;
                }
                .bar4 {
                    -webkit-transform: rotate(90deg) translate(0, -142%);
                            transform: rotate(90deg) translate(0, -142%);
                    -webkit-animation-delay: -0.75s;
                            animation-delay: -0.75s;
                }
                .bar5 {
                    -webkit-transform: rotate(120deg) translate(0, -142%);
                            transform: rotate(120deg) translate(0, -142%);
                    -webkit-animation-delay: -0.66666667s;
                            animation-delay: -0.66666667s;
                }
                .bar6 {
                    -webkit-transform: rotate(150deg) translate(0, -142%);
                            transform: rotate(150deg) translate(0, -142%);
                    -webkit-animation-delay: -0.58333333s;
                            animation-delay: -0.58333333s;
                }
                .bar7 {
                    -webkit-transform: rotate(180deg) translate(0, -142%);
                            transform: rotate(180deg) translate(0, -142%);
                    -webkit-animation-delay: -0.5s;
                            animation-delay: -0.5s;
                }
                .bar8 {
                    -webkit-transform: rotate(210deg) translate(0, -142%);
                            transform: rotate(210deg) translate(0, -142%);
                    -webkit-animation-delay: -0.41666667s;
                            animation-delay: -0.41666667s;
                }
                .bar9 {
                    -webkit-transform: rotate(240deg) translate(0, -142%);
                            transform: rotate(240deg) translate(0, -142%);
                    -webkit-animation-delay: -0.33333333s;
                            animation-delay: -0.33333333s;
                }
                .bar10 {
                    -webkit-transform: rotate(270deg) translate(0, -142%);
                            transform: rotate(270deg) translate(0, -142%);
                    -webkit-animation-delay: -0.25s;
                            animation-delay: -0.25s;
                }
                .bar11 {
                    -webkit-transform: rotate(300deg) translate(0, -142%);
                            transform: rotate(300deg) translate(0, -142%);
                    -webkit-animation-delay: -0.16666667s;
                            animation-delay: -0.16666667s;
                }
                .bar12 {
                    -webkit-transform: rotate(330deg) translate(0, -142%);
                            transform: rotate(330deg) translate(0, -142%);
                    -webkit-animation-delay: -0.08333333s;
                            animation-delay: -0.08333333s;
                }
            }
        }
    }
}

/**
 * Tec Spinner
 */
.busy-spinner.tec {
    .busy-spinner-default-wrapper {
        .busy-spinner-default-sign {
            .busy-spinner-default-spinner {
                div {
                    background: $primary-color;
                }
            }
        }
    }
}
