@import "variables";

.versionBubble {
  border-radius: 5px !important;
  z-index: 10000;
  .header {
    font-weight: bold;
    font-size: 16px;
    background-color: #3F9DD1;
    color: $white !important;
  }

  .content {
    padding-top: 10px;
    border-bottom: solid 1px $tab-border-selected;
    padding-bottom: 20px;
  }

  .footer {
    padding-top: 10px;
    padding-bottom: 20px;

    .btn_reload_page {
      background-color: #216489 !important;
    }
  }
}
