.vehicles-container {
  .make-tabs-row {

    padding-bottom: 15px;
    text-align: center;
    align-content: center;

    .make-tabs-wrapper {
      margin: 0 auto 0 auto !important;
      width: 500px !important;
      float: none !important;

      .ui-tabmenu {

        .ui-tabmenu-nav {
          border-bottom: none !important;

          .ui-tabmenuitem {
            padding: 3px 0 0 0 !important;
            margin-right: 15px !important;
            min-width: 145px;
            text-align: center !important;
            height: 35px !important;
            vertical-align: middle !important;

            * {
              float: none !important;
              vertical-align: middle !important;
              margin: 0 !important;
            }

            &.ui-state-active {
              font-weight: bold;
            }
          }

        }
      }
    }
  }
}
