@import "variables";

.ui-multiselect-label {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.row-grid {
  [class*="col-"] {
    padding-bottom: 15px;
  }

  [class="col"] {
    padding-bottom: 15px;
  }

  [class*="col-"] [class*="col-"] {
    padding-bottom: 15px;
  }
}

