@import "variables";

.analytics-filter {
  .card {
    height: 100%;
    .card-body {
      background-color: #F8F9FA;
    }
  }
}

.card {
  .card-header {
    display: inline-block !important;
    padding: 4px 10px 8px 10px !important;
    background: $ta-panel-header-background-color 0% 0% no-repeat padding-box;
    border: 1px solid $ta-panel-border-color;
    border-radius: 3px 3px 0px 0px;
    height: 45px;
    color: $ta-text-color;
    >span{
      margin-right: 10px;
      line-height: 33px!important;
      &.label{
        font: normal normal normal 16px/22px $ta-font;
      }
      &.center {
        position: absolute;
        left: 40%;
      }
    }

    > h6 {
      line-height: 33px !important;
    }

    button {
      width: 33px;
      height: 33px;
      background: $ta-panel-header-background-color 0% 0% no-repeat padding-box;
      border-radius: 3px;
      padding: 0px !important;
      margin: auto 0 auto 10px;
      color: $ta-active-color;
      font: normal normal normal 16px/24px;
      &:hover{
        background-color: #ECEEEF !important;
      }
      &:focus{
        box-shadow: 0px 0px 5px $ta-primary-color;
        border: 1px solid $ta-active-color;
      }
    }
  }
  .card-body {

  }
  .card-footer {

  }
}

body .ui-panel {
  .ui-panel-titlebar {
    padding: 9px 15px;
    background-color: #003366;
    color: #fff;
    button {
      background-color: #216489;
      border: 1px solid #003366;
      padding: 5px 10px;
      margin-left: 10px;
      &::before {
        color: #fff;
      }
    }
  }
}


.fillhoriz {
  width: 100%;
}

.fillvert {
  height: 100vh;
}

.ui-dialog {
  fillhoriz {
    width: 100%;
  }

  .fillvert {
    height: 80vh;
  }
}


