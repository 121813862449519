@import "../scss/variables";

most-searched-visitors {

  .sources-box-header {

    > .bar-label {
      padding-top: 10px;
    }

    padding: 4px 10px 8px 10px !important;
    background: $ta-panel-header-background-color 0% 0% no-repeat padding-box !important;
    border: 1px solid $ta-panel-border-color;
    border-radius: 3px 3px 0px 0px;
    height: 45px;
    color: $ta-text-color;
  }

  .trafficTable {
    .ui-sortable-column-icon {
      display: none !important;
    }
  }

  .user-box-header-toolbar {

    > div {
      display: inline;
      margin-left: 10px;

      > div {
        width: 2rem;
        height: 2rem;
        vertical-align: middle
      }

    }

    button {
      width: 33px;
      height: 33px;
      background: $ta-panel-header-background-color 0% 0% no-repeat padding-box;
      border-radius: 3px;
      padding: 0px !important;
      margin: auto 0 auto 10px;
      color: $ta-primary-color;
      font: normal normal normal 16px/24px;

      &:hover {
        background: $ta-panel-border-color 0% 0% no-repeat padding-box;
        color: $ta-primary-hover-color;
      }

      &:focus {
        background: $ta-light-color 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 5px $ta-primary-color;
        border: 1px solid $ta-primary-color;
        color: $ta-text-color;
      }
    }
  }

  div.top-selector {
    width: 80px;
    min-width: 80px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #AFB4B9;
    border-radius: 3px
  }

  div.site-traffic {
    margin: 5px;

    > div.card-body {
      font-weight: bold;

      > h5.card-title {
        font-size: medium;
      }

      > p.card-text {
        font-size: large;
      }
    }
  }

  div.row.channel {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  div.row.source {
    margin-top: 20px;
    margin-bottom: 10px;
  }

}
